import React, { useState } from "react";
import Logo from "./Logo";
import { TbMenu } from "react-icons/tb";
import { CgClose } from "react-icons/cg";
import { FaAngleDown } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export const menus = [
  {
    key: "#schools",
    label: "For schools 🏫",
    path: "https://school.nurovant.com/",
  },
  {
    key: "#educators",
    label: "For educators 👩‍🏫",
    path: "https://school.nurovant.com/",
  },
  {
    key: "#students",
    label: "For students 👨‍🎓",
    path: "https://app.nurovant.com/",
  },
  {
    key: "#more",
    label: "More ✨",
    path: "https://school.nurovant.com/",
    children: [
      "For tutoring companies",
      "For content providers",
      "For workforce boards",
    ]
  },
  {
    label: "Blog 📚",
    key: "https://blog.nurovant.com/",
    path: "https://blog.nurovant.com/",
  },
]

const Header = () => {
  const { hash } = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen(!isOpen)

  const isLoginActive = Boolean(hash === "#login")
  // const menu = menus.find(({key}, d) => (hash ? (key === hash) : !d))
  return (
    <div className="w-full p-5 sm:px-10 md:px-20 sticky top-0 bg-transparent z-30">
      <div className={"w-full bg-[#F5F5F5E5] rounded-full p-5 md:py-2 flex flex-col md:flex-row items-center gap-5 md:!backdrop-blur-0 ".concat(String(isOpen && "!rounded-3xl"))}>
        <div className="w-full md:w-auto flex justify-between items-center">
          <Logo />
          <button className="block md:hidden" onClick={handleOpen}>
            {isOpen ? <CgClose className="!text-2xl" /> : <TbMenu className="!text-2xl" />}
          </button>
        </div>

        <div className={"w-full hidden md:flex flex-col md:flex-row md:justify-between md:items-center gap-5 ".concat(String(isOpen && "!flex"))}>
          <div />

          <div className="md:rounded-full p-[1px] md:bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40] relative">
            <div className="md:p-1 md:bg-white md:rounded-full flex flex-col md:flex-row md:justify-between md:items-center gap-5 md:gap-0">
              {menus.map(({label, key, children}, d) => {
                // const isActive = Boolean(key === hash)
                const hasChild = Boolean(children?.length)
                const isKey = Boolean(key?.includes("http"))
                const isActive = Boolean(hash ? (key === hash) : !d)
                const isChildActive = Boolean(isActive && hasChild)
                return (
                  <div className="w-full">
                    <div className={"w-full md:rounded-full md:p-[1px] ".concat(String(isActive && "md:bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40] md:shadow"))}>
                      <a href={isKey ? key : "/contact".concat(key)} target={isKey ? "_blank" : "_self"} rel="noreferrer" className="flex items-center gap-2 !whitespace-nowrap md:rounded-full md:bg-white md:hover:bg-[#F5F5F5] sm:px-3 sm:py-2 md:px-5">
                        <p className="text-base font-bold">{label}</p>
                        <FaAngleDown className={String(!hasChild && "!hidden")} />
                      </a>
                    </div>

                    <div className={"w-full rounded-xl p-5 grid grid-cols-1 sm:grid-cols-3 gap-5 absolute -bottom-40 md:-bottom-20 left-0 bg-white md:bg-[#F5F5F5E5] shadow-2xl ".concat(String(!isChildActive && "!hidden"))}>
                      {children?.map(child => <a href={"/contact#".concat(child)} className="block text-base font-bold text-[#161617] hover:text-primary">{child}</a>)}
                    </div>
                  </div>
              )})}
            </div>
          </div>
          
          <div className="flex flex-col md:flex-row md:items-center gap-5 md:gap-3 relative">
            <div onClick={handleOpen} className="md:rounded-full md:p-[1px] md:bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
              <a href="#login" className="block md:bg-white md:hover:bg-[#F5F5F5] sm:p-2 md:px-3 rounded-full text-base font-bold">Login</a>
            </div>
            <a onClick={handleOpen} href="/contact#See free demo" className="block text-center text-white bg-[#4970FC] hover:bg-[#093EFB] p-2 px-5 rounded-full text-base font-bold">See free demo 👀</a>

            <div className={"w-full rounded-xl p-5 grid grid-cols-1 sm:grid-cols-2 gap-5 absolute -bottom-40 md:-bottom-20 left-0 bg-white md:bg-[#F5F5F5E5] shadow-2xl ".concat(String(!isLoginActive && "!hidden"))}>
              {[
                {label: "For Students", path: "https://app.nurovant.com"},
                {label: "For Teachers", path: "https://school.nurovant.com"},
              ]?.map(({label, path}) => <a href={path} target="_blank" rel="noreferrer" className="block text-base font-bold text-[#161617] hover:text-primary">{label}</a>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
