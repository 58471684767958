import About from "../pages/about";
import Auth from "../pages/auth";
import Contact from "../pages/contact";
import Home from "../pages/home";
import Institutions from "../pages/institutions";
import Students from "../pages/students";
import Teachers from "../pages/teachers";

export const routes = [
  {
    path: "/",
    label: "Home",
    component: <Home />,
  },
  {
    path: "/auth",
    label: "Auth",
    component: <Auth />,
  },
  {
    path: "/students",
    label: "Students",
    component: <Students />,
  },
  {
    path: "/teachers",
    label: "Teachers",
    component: <Teachers />,
  },
  {
    path: "/institutions",
    label: "Institutions",
    component: <Institutions />,
  },
  {
    path: "/about-us",
    label: "About Us",
    component: <About />,
  },
  {
    path: "/contact",
    label: "Contact Us",
    component: <Contact />,
  },
];
