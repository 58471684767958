import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../assets/home/logo.svg";

type Props = {
  className?: string
}
function Logo({
  className,
}: Props) {
  return (
    <Link to="/" className={className}>
      <img
        src={logo}
        alt="nurovant logo"
        className="!w-[120px]"
      />
    </Link>
  )
}

export default Logo