import React from "react";
import hero from "../../assets/about/hero.png";

type Props = {};

const Hero = (props: Props) => {
  return (
    <main className="flex min-h-[calc(100dvh-88px)] items-center bg-[#F9F1E5] px-4 pb-6 lg:bg-gray-100 lg:px-20">
      <section className="mx-auto flex flex-col gap-6 rounded-3xl bg-[#F9F1E5] lg:flex-row lg:items-center lg:justify-between lg:px-[48px] lg:py-[121px] xl:w-[1267px]">
        <article className="=lg:mx-0 mx-auto max-w-[532px] text-center lg:text-left">
          <h1 className="mx-auto max-w-[410px] font-GontserratBold text-[40px] font-semibold lg:mx-0 lg:text-[56px]">
            Enhanced Collaboration
          </h1>
          <p className="mt-6 text-[20px] font-medium text-[#646462]">
            Foster collaboration between teachers and students, creating an
            interactive learning environment that promotes knowledge sharing and
            engagement.
          </p>
        </article>
        <figure className="mt-10 lg:mt-0">
          <img
            src={hero}
            alt=""
            className="lg:mx-object-contain mx-auto max-h-[395px] w-full max-w-[458px]"
          />
        </figure>
      </section>
    </main>
  );
};

export default Hero;
