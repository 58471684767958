import React, { useEffect, useState } from "react";
import { studyInfo, studyPerks } from "../../lib/students-info";
import image1 from "../../assets/students/image1.png";
import image2 from "../../assets/students/image2.png";
import image3 from "../../assets/students/image3.png";
import image1Mobile from "../../assets/students/image1-mobile.png";

import { cn } from "../../lib/utils";
import DownloadApps from "../DownloadApps";

type Props = {};

const Information = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const getImage = () => {
    if (activeIndex === 0) {
      return image1;
    }
    if (activeIndex === 1) {
      return image2;
    }
    if (activeIndex === 2) {
      return image3;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % 3);
    }, 2000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <main className="bg-[#E6F1FE] px-4 py-10 lg:px-20 lg:py-[72px]">
      <h1 className="mx-auto max-w-[700px] text-center  font-GontserratBold text-[28px]">
        Develop effective study habits, and maximize their learning potential
      </h1>
      <figure className="mx-auto w-fit xl:hidden">
        <img
          src={image1Mobile}
          alt=""
          className="mt-8 max-h-[416px] w-full max-w-[343px] object-contain"
        />
      </figure>
      <section className="mx-auto mt-8 flex max-w-[1280px] justify-between">
        <aside className="mx-auto max-w-[576px] xl:mx-0">
          {studyInfo.map((info, i) => (
            <div
              className={cn(
                "space-y-2 px-3 py-4 transition-all duration-500 ease-in xl:border-l-[5px] xl:border-gray-100",
                i === activeIndex && "xl:border-primary",
              )}
            >
              <h2
                className={cn(
                  "text-[29px] font-semibold transition-all duration-500 ease-in lg:text-[24px]",
                  i === activeIndex && "xl:text-primary",
                )}
              >
                {info.title}
              </h2>
              <p className="text-base">{info.text}</p>
            </div>
          ))}
        </aside>
        <figure className="hidden xl:block">
          <img
            src={getImage()}
            alt=""
            className="h-[560px] w-[576px] object-contain"
          />
        </figure>
      </section>
      <section className="mx-auto max-w-[1280px] py-10 xl:py-14">
        <h1 className="mx-auto max-w-[311px] text-center text-[28px] font-bold xl:max-w-full xl:text-left">
          Explore the perks in store for you
        </h1>
        <div className=" mx-auto mt-12 flex w-fit flex-col gap-8 xl:mx-0 xl:flex-row">
          {studyPerks.map((perk, i) => (
            <aside key={i} className="max-w-[576px] xl:max-w-[384px]">
              <h1 className="text-[18px] font-semibold">{perk.title}</h1>
              <p className="text-base text-[#1B1B1B]">{perk.text}</p>
            </aside>
          ))}
        </div>
      </section>
      <DownloadApps
        title="Ready to ace those exams?"
        text="Sign up for a free trial today and discover the endless possibilities for enhancing student well-being and academic success!"
      />
    </main>
  );
};

export default Information;
