import React from "react";
import Hero from "../../components/teachers/Hero";
import Information from "../../components/teachers/Information";

type Props = {};

const Teachers = (props: Props) => {
  return (
    <main>
      <section>
        <Hero />
      </section>
      <section className="mt-8 lg:mt-0">
        <Information />
      </section>
    </main>
  );
};

export default Teachers;
