import React from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const WatchDemoBtn = () => {
  const { pathname } = useLocation();

  const handleScroll = () => {
    const element = document.getElementById("watch-demo");
    element?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
      {pathname === "/contact" ? (
        <HashLink
          to="/#watch-demo"
          smooth
          className="block bg-white cursor-text p-3 px-5 rounded-full text-base font-bold"
        >
          Watch Demo 🎥
        </HashLink>
      ) : (
        <span
          className="block bg-white cursor-text p-3 px-5 rounded-full text-base font-bold"
          onClick={handleScroll}
        >
          Watch Demo 🎥
        </span>
      )}
    </div>
  );
};

export default WatchDemoBtn;
