import React from "react";
import Hero from "../../components/about/Hero";
import Newsletter from "../../components/about/Newsletter";
import SendMail from "../../components/about/SendMail";

type Props = {};

const About = (props: Props) => {
  return (
    <main className="bg-white">
      <section>
        <Hero />
      </section>
      <section>
        <Newsletter />
      </section>
      <section>
        <SendMail />
      </section>
    </main>
  );
};

export default About;
