import React from "react";
import stars from "../assets/home/stars.svg";
import googleplay from "../assets/googleplay.svg";
import applestore from "../assets/applestore.svg";
import nurovantBird1 from "../assets/home/nurovant-bird-1.svg";
import starsMobile from "../assets/stars-mobile.svg";
import stars2Mobile from "../assets/stars2-mobile.svg";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  text: string;
};

const DownloadApps = ({ title, text }: Props) => {
  return (
    <main className="w-full">
      <section className="relative px-4 py-[96px]">
        <figure>
          <img
            src={nurovantBird1}
            alt=""
            className="mx-auto h-[153px] w-[228px] object-contain"
          />
        </figure>

        <article className="relative z-20">
          <figure className="absolute -top-[150%] left-0 right-0 mx-auto flex w-full max-w-[872px] justify-between">
            <img
              src={stars}
              alt=""
              className="left-0 z-10 hidden h-auto w-[183px] object-contain md:block"
            />
            <img
              src={stars}
              alt=""
              className="z-10 hidden h-auto w-[183px] object-contain md:block"
            />
          </figure>
          <div className="relative z-20 mx-auto w-fit">
            <h1 className="relative z-20 mx-auto w-fit text-center text-[30px] font-semibold lg:text-[48px] lg:font-bold">
              {title}
            </h1>
            <img
              src={stars2Mobile}
              alt=""
              className="absolute -top-[440%] left-0 z-10 h-auto w-[183px] object-contain md:hidden"
            />
            <img
              src={starsMobile}
              alt=""
              className="absolute -top-[440%] right-0 z-10 h-auto w-[183px] object-contain md:hidden"
            />
          </div>
          <p className="relative z-20 mx-auto max-w-[658px] text-center font-medium text-[#646462]">
            {text}
          </p>
        </article>
        <div className="relative z-20 mx-auto mt-6 flex w-fit items-center gap-4">
          <Link
            target="_blank"
            to="https://play.google.com/store/apps/details?id=com.reflectly.ai&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img
              src={googleplay}
              alt=""
              className="h-[49px] w-[163px] object-contain"
            />
          </Link>

          <Link
            target="_blank"
            to="https://apps.apple.com/ng/app/nurovant-ai/id6461046210"
          >
            <img
              src={applestore}
              alt=""
              className="h-[49px] w-[163px] object-contain"
            />
          </Link>
        </div>
      </section>
    </main>
  );
};

export default DownloadApps;
