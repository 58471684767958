import React from "react";
import { HeaderTag } from "../HeaderTag";
import AlexImg from "../../assets/alex.svg";
import JordonImg from "../../assets/jordan.svg";
import LiamImg from "../../assets/liam.svg";
import JohnImg from "../../assets/john.svg";

interface Testimonial {
  name: string;
  role?: string;
  quote: string;
  avatar: string;
  bgColor: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Alex",
    role: "Computer Science Student",
    quote:
      "As someone who juggles part-time work with a full course load, Nurovant AI's personalized study plans have been a game-changer. The app helps me stay organized and on track. Plus, the flashcards are perfect for quick revision sessions between classes.",
    avatar: AlexImg,
    bgColor: "bg-[#E1E7FF]",
  },
  {
    name: "Jordon",
    role: "Graduate Student",
    quote:
      "I used to get overwhelmed with the amount of reading in my major, but Nurovant AI has made it so much more manageable. The ability to upload PDFs and turn dense texts into key points and study aids is incredible. It's like having a personal tutor.",
    avatar: JordonImg,
    bgColor: "bg-[#FEF7D5]",
  },
  {
    name: "Liam",
    role: "High School Student",
    quote:
      "Love how Nurovant AI adapts to my learning style. The quizzes are tailored to what I've studied, and they really help reinforce the material. It's not just an app; it's a comprehensive learning system.",
    avatar: LiamImg,
    bgColor: "bg-[#FCF5EB]",
  },
  {
    name: "David P",
    role: "High School Teacher",
    quote:
      "My students love Nurovant! It gives them confidence in tackling challenging subjects, and I’ve noticed significant improvements in their grades.",
    avatar: JohnImg,
    bgColor: "bg-[#F1FAFF]",
  },
  {
    name: "Nina L",
    role: "NBA Candidate",
    quote:
      "Nurovant has been like a personal tutor! It simplifies lectures and breaks down case studies, helping me grasp topics that would otherwise take hours to understand",
    avatar: LiamImg,
    bgColor: "bg-[#D9F1F0]",
  },
  {
    name: "Rachel S",
    role: "Ph.D Researcher",
    quote:
      "Nurovant’s insights are invaluable. It allows me to focus on my research rather than getting bogged down in dense reading material.",
    avatar: JohnImg,
    bgColor: "bg-[#FDEBEE]",
  },
  {
    name: "James M",
    role: "Data Scientist",
    quote:
      "As a professional constantly learning new skills, Nurovant has saved me countless hours. Its summaries and insights allow me to stay sharp and focused on what really matters.",
    avatar: AlexImg,
    bgColor: "bg-[#F9F3FA]",
  },
  {
    name: "Tom A",
    role: "Medical Student",
    quote:
      "With Nurovant, studying intense medical content feels so much easier. The AI distills complex information, making revision sessions shorter and more productive!",
    avatar: JordonImg,
    bgColor: "bg-[#E7E7E7]",
  },
];

const Testimonials = () => {
  return (
    <div className="w-full py-[80px] lg:py-[120px]  mx-auto max-w-7xl px-4 lg:px-0 flex flex-col gap-[60px] items-center justify-center">
      <div className="flex flex-col gap-5 items-center justify-center">
        <HeaderTag text="What they say 😁" />
        <h4 className="text-[30px] leading-[38px] lg:text-[36px] lg:leading-[48px] font-semibold text-center max-w-[968px]">
          Over 500,000 Students, Professionals, and Researchers have used
          Nurovant to stay ahead
        </h4>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`${testimonial.bgColor} rounded-[24px] p-6 shadow-md flex flex-col justify-between`}
          >
            <div>
              <div className="flex items-center mb-4">
                <div className="rounded-full mr-4 w-[48px] h-[48px] overflow-hidden">
                  <img
                    src={testimonial.avatar}
                    alt={`Avatar of ${testimonial.name}`}
                    className="w-full"
                  />
                </div>
                <div>
                  <h3 className="font-bold text-[13px] leading-[19px] text-[#161617] font-montserrat">
                    - {testimonial.name}
                  </h3>
                  {testimonial.role && (
                    <p className="text-[12px] leading-[18px] text-[#9D9E9A] font-montserrat">
                      {testimonial.role}
                    </p>
                  )}
                </div>
              </div>
              <p className="text-[#414244] text-sm">{testimonial.quote}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
