import React from "react";
import { menus } from "../Header";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function TopHero() {
  const { hash } = useLocation();
  const menu = menus.find(({ key }, d) => (hash ? key === hash || !d : !d));

  return (
    <div className="w-full min-h-screen bg-home-bg bg-no-repeat bg-cover bg-center p-5 sm:p-10 md:p-20 space-y-20 !pb-0" id="announcements">
      <div className="w-full h-full flex flex-col justify-center items-center text-center space-y-5">
        <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
          <p className="block bg-[#E1E7FF] cursor-text p-1 px-3 rounded-full text-sm font-semibold text-[#4970FC]">Announcements</p>
        </div>
        <p className="md:px-20 text-3xl md:text-5xl font-bold text-[#161617] md:leading-[60px]">
          Unlock learning with AI-driven personalization for 
          <br className="hidden md:block" /> faster progress and better results.
        </p>
        <p className="text-base md:text-lg font-semibold text-[#57585A]">
          Supercharge your content with adaptive learning that personalizes 
          content for each <br className="hidden md:block" /> student, driving 
          faster progress and better results.
        </p>
        <div className="w-full flex flex-row justify-center items-center gap-5">
          <Link
            to="/auth"
            className="block text-center text-white bg-[#4970FC] hover:bg-[#093EFB] p-3 px-5 rounded-full text-base font-bold"
          >
            Get Started 🔥
          </Link>
          <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/results?search_query=nurovant"
              className="block bg-white hover:bg-[#F5F5F5] p-3 px-5 rounded-full text-base font-bold"
            >
              Watch Demo 🎥
            </a>
          </div>
        </div>
      </div>

      <div
        className="w-full h-[30vh] sm:h-[50vh] md:h-[100vh] lg:h-[110vh] md:p-1 rounded-t-3xl b-gradient-to-b from-[#D8B4E240] to-[#4970FC40] !bg-none"
        id="watch-demo"
      >
        <iframe
          loading="lazy"
          allowFullScreen
          allow="clipboard-write"
          className="!w-full !h-full !rounded-t-3xl"
          title="Nurovant AI Adaptive learning system."
          src="https://app.supademo.com/embed/cm1ufeptx033062k5tmgilz35?v_email=EMAIL&embed_v=2"
        />
      </div>
    </div>
  );
}

export default TopHero;
