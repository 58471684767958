import React from "react";
import { HeaderTag } from "../HeaderTag";
import { handleScroll } from "./Faq";
import WatchDemoBtn from "../WatchDemoBtn";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <div className="w-full max-w-7xl mx-auto px-4 lg:px-0 py-[80px] lg:py-[120px]">
      <div className="w-full h-[519px] rounded-[30px] bg-cta-bg bg-cover bg-center flex items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-[30px] px-4 lg:px-0 lg:gap-[60px]">
          <HeaderTag text="Lets go 🔥" />
          <div className="flex flex-col gap-[15px] items-center justify-center">
            <h3 className="text-[36px] leading-[44px] text-center font-bold text-[#101828]">
              Ready to ace those exams?
            </h3>
            <span className="text-[18px] leading-[28px] text-[#161617] font-semibold max-w-2xl text-center">
              Sign up for a free trial today and discover the endless
              possibilities for enhancing student well-being and academic
              success!
            </span>
          </div>

          <div className="w-full flex flex-row justify-center items-center gap-5">
            <Link
              to="/auth"
              // href={"https://school.nurovant.com/"}
              className="flex text-center text-white bg-[#4970FC] hover:bg-[#4970FC] hover:text-white p-3 px-5 rounded-full text-base font-bold items-center justify-center"
            >
              Get Started 🔥
            </Link>
            <WatchDemoBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
