import image1 from "../assets/teachers/image1.png";
import image2 from "../assets/teachers/image2.png";
import image3 from "../assets/teachers/image3.png";

export const institutionsInfo = [
  {
    id: 0,
    title: "Customizable Learning Platform",
    text: "Tailor Nurovant to fit the unique needs and goals of your institution. Customize features, branding, and content to create a personalized learning environment for your students and faculty.",
    image: image1,
  },
  {
    id: 1,
    title: "Comprehensive Analytics",
    text: "Track key metrics, identify trends, and make data-driven decisions to optimize learning outcomes.",
    image: image2,
  },
  {
    id: 2,
    title: "Integrated Curriculum Support",
    text: "Seamlessly integrate Nurovant into your existing curriculum with our versatile platform.",
    image: image3,
  },
];

export const institutionsPerks = [
  {
    title: "Faculty Empowerment",
    text: "Support faculty professional growth and development with access to training, resources, and collaborative opportunities to enhance teaching practices and student engagement.",
  },
  {
    title: "Student Engagement",
    text: "Promote active learning and student engagement with interactive tools, personalised learning experiences, and supportive resources tailored to their needs.",
  },
  {
    title: "Institutional Reputation",
    text: "Elevate your institution's reputation as a leader in innovative education and student-centered learning approaches with Nurovant's cutting-edge platform.",
  },
];
