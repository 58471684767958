import React from "react";
import ContactForm from "../../components/contact/Form";
import Cta from "../../components/home/Cta";
import Faq from "../../components/home/Faq";

function Contact() {
  return (
    <div className="w-full">
      <ContactForm />
      <Faq />
      <Cta />
    </div>
  );
}

export default Contact;
