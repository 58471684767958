import React from "react";
import hero from "../../assets/about/hero.png";

type Props = {};

const Hero = (props: Props) => {
  return (
    <main className="flex w-fit flex-col gap-16  px-4 pb-6 pt-24 lg:mx-auto lg:flex-row lg:px-20">
      <aside className="text-center lg:text-left">
        <h1 className="font-GontserratBold text-[24px] font-bold lg:max-w-[627px] lg:text-[48px]">
          Our mission is to redefine the learning experience.
        </h1>
      </aside>
      <article>
        <p className="font-montserrat text-base lg:max-w-[597px]">
          Nurovant AI. was established in 2023 in Dallas, Texas. Founded by
          Trevor Gicheru, alongside a dedicated team of engineers, who are
          committed to driving innovation at the intersection of artificial
          intelligence and education. Our vision is to create intuitive learning
          tools that simplify complex educational content, making learning
          accessible and engaging for everyone
        </p>
      </article>
    </main>
  );
};

export default Hero;
