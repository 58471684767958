import React from "react";
import ipad from "../../assets/teachers/iPad.png";

type Props = {};

const Hero = (props: Props) => {
  return (
    <main className="w-ful flex min-h-[calc(100dvh-88px)] items-center bg-white pb-6 pl-4">
      <section className="flex w-full flex-col gap-6 lg:flex-row lg:items-center lg:justify-between lg:py-[121px] lg:pl-[48px]">
        <article className="mx-auto max-w-[745px] text-center lg:mx-0 lg:text-left">
          <h1 className="mx-auto font-GontserratBold text-[40px] font-semibold lg:mx-0 lg:text-[56px]">
            Elevate Your Teaching with Nurovant
          </h1>
          <p className="mt-6 text-[20px] font-medium text-[#646462]">
            Our state-of-the-art AI software is tailored to enhance every aspect
            of your teaching experience, from streamlining administrative tasks
            to fostering engaging, interactive learning environments. Dive into
            a world where technology elevates education, making every lesson
            impactful and every classroom a hub of success.
          </p>
        </article>
        <figure className="mt-10 hidden flex-shrink-0 lg:mt-0 lg:block">
          <img
            src={ipad}
            alt=""
            className="lg:mx- mx-auto h-[680px] w-[509px] object-contain"
          />
        </figure>
      </section>
    </main>
  );
};

export default Hero;
