import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <div className="w-full h-full">
      <Header />
      <section className="font-montserrat">{children}</section>
      <footer className="font-montserrat">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
