import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import CustomRoutes from "./router";
import Layout from "./layouts/layout";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <CustomRoutes />
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App;
