import React from "react";
import image5 from "../../assets/home/image 5.svg";
import image6 from "../../assets/home/image 6.svg";
import image7 from "../../assets/home/image 7.svg";
import image8 from "../../assets/home/image 8.svg";
import image9 from "../../assets/home/image 9.svg";
import Slider from "react-infinite-logo-slider";

type Props = {};

const LogoSlider = (props: Props) => {
  const images = [image5, image6, image7, image8, image9];
  return (
    <div className="w-full bg-[#F9F9F9] sm:py-5 sm:px-10 md:px-20 lg:px-40">
      <Slider
        blurBorders
        width="250px"
      >
        {images.map((image, index) => (
          <Slider.Slide>
            <img
              key={index}
              src={image}
              alt={`logo ${index + 1}`}
            />
          </Slider.Slide>
        ))}
      </Slider>
    </div>
  );
};

export default LogoSlider;
