import React, { useState } from "react";
import { Button } from "../ui/button";

type Props = {};

const SendMail = (props: Props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const submitForm = (e: any) => {
    e.preventDefault();
    const encodedName = encodeURIComponent(name);
    const encodedMessage = encodeURIComponent(message);
    window.open(
      `mailto:support@nurovant.com?subject=from%20${encodedName}&body=${encodedMessage}`,
    );
  };
  return (
    <main className="mt-[144px] px-4 pb-[97px] text-center lg:px-20">
      <h1 className="text-[32px] font-semibold">Get in touch</h1>
      <p className="text-base font-semibold text-[#667085]">
        We&apos;d love to hear from you. Please fill out this form.
      </p>
      <form
        onSubmit={submitForm}
        className="mx-auto mt-[48px] max-w-[474px] space-y-8"
      >
        <div className="flex flex-col">
          <label
            htmlFor=""
            className="z-20 ml-4 w-fit rounded-full border border-[#D1D1D1] bg-black px-[10px] py-[5px] text-[10px] font-semibold text-white"
          >
            Full Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="John Doe"
            className="-mt-2 w-full rounded-xl border border-[#D1D1D1] px-[27px] py-[14px] focus:border-primary focus:outline-primary"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor=""
            className="z-20 ml-4 w-fit rounded-full border border-[#D1D1D1] bg-black px-[10px] py-[5px] text-[10px] font-semibold text-white"
          >
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="johndoe@email.com"
            className="-mt-2 w-full rounded-xl border border-[#D1D1D1] px-[27px] py-[14px] focus:border-primary focus:outline-primary"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor=""
            className="z-20 ml-4 w-fit rounded-full border border-[#D1D1D1] bg-black px-[10px] py-[5px] text-[10px] font-semibold text-white"
          >
            Message
          </label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="-mt-2 h-[167px] w-full resize-none rounded-xl border border-[#D1D1D1] px-[27px] py-[14px] focus:border-primary focus:outline-primary"
          />
        </div>
        <button className="w-full rounded-full bg-primary py-[17px] text-[18px]  font-normal text-white">
          Send Message
        </button>
      </form>
    </main>
  );
};

export default SendMail;
