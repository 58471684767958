import React from 'react'
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

function Benefits() {

  const benefits = [
    {
      label: "Detect Lagging Students Early",
      before: "Struggling students slip through the cracks, and by the  time it’s noticed, the damage is done—leading to poor grades, disengagement, and overwhelmed teachers trying to catch them up.",
      after: "Instantly identify which micro-concepts each student is struggling with, allowing you to intervene precisely and early, turning potential failures into success stories.",
    },
    {
      label: "Personalized Learning Experience for Every Student",
      before: "Your brightest students are bored, while others feel left behind. One-size-fits-all teaching leaves everyone disengaged.",
      after: "Provide each student with a tailored path, keeping them challenged and engaged at their level, every single day.",
    },
    {
      label: "Identify Specific Learning Gaps",
      before: "Students struggle without clear signs, and you're left wondering where they went wrong, wasting valuable class time on guesswork.",
      after: " Pinpoint exact gaps in knowledge, so you can focus on what truly matters and ensure no student is left behind.",
    },
    {
      label: "Immediate Feedback for Students and Educators",
      before: "Delayed feedback means students keep making the same mistakes, while teachers waste time reviewing outdated performance data.",
      after: "Provide immediate feedback to drive faster improvement, while you gain instant insights to adjust instruction on the spot",
    },
    {
      label: "Reducing Teacher Workload",
      before: "Hours lost grading, creating lesson plans, and trying to meet individual needs—leaving you exhausted and stretched too thin.",
      after: "Free yourself from the tedious tasks. Let automation handle it while you focus on teaching and making a real impact.",
    },
    {
      label: "Micro-Concept Mastery",
      before: "Students advance without fully understanding the basics, creating learning gaps that snowball into larger problems.",
      after: "Ensure students master every concept, step by step, so they’re always prepared to succeed in the next lesson.",
    },
    {
      label: "Speed Up the Learning Process",
      before: "Students waste time re-learning what they already know, slowing progress and creating frustration.",
      after: "Allow students to accelerate through familiar material, focusing only on what they need to master.",
    },
    {
      label: "Scalable Learning Solutions",
      before: "Growing class sizes make it impossible to maintain the same quality of education across the board, leaving some students underserved.",
      after: "Scale effortlessly, delivering consistent results whether you’re managing one student or an entire class.",
    },
  ]
  return (
    <div className='w-full min-h-screen flex flex-col items-center text-center bg-white p-5 sm:p-10 md:p-20 space-y-10' id='benefits'>
      <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
        <p className="block bg-white cursor-text p-1 px-3 rounded-full text-sm font-semibold text-[#161617]">Benefits 💐</p>
      </div>
      <p className='md:px-20 text-3xl md:text-5xl font-bold text-[#161617] md:leading-[60px]'>Maximize efficiency, achieve results, and elevate <br className='hidden md:block' /> your experience.</p>
      <div className='w-full md:px-20 hidden md:grid grid-cols-1 md:grid-cols-2 gap-5'>
        <div className='flex justify-center items-center gap-3'>
          <p className='text-base font-bold text-[#161617]'>Before</p>
          <AiFillCloseCircle className='text-xl text-[#F04438]' />
        </div>

        <div className='flex justify-center items-center gap-3'>
          <p className='text-base font-bold text-[#161617]'>After</p>
          <AiFillCheckCircle className='text-xl text-[#17B26A]' />
        </div>
      </div>
      <div className='md:px-20 space-y-10'>
        {benefits.map(({label, before, after}) => (
          <div className='w-full space-y-2 !text-start'>
            <p className='text-xl font-bold text-[#161617]'>{label}</p>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10'>
              <div className='space-y-2'>
                <div className='flex md:hidden gap-3'>
                  <p className='text-base font-bold text-[#161617]'>Before</p>
                  <AiFillCloseCircle className='text-xl text-[#F04438]' />
                </div>
                <div className='flex items-center gap-3'>
                  <div className='min-h-[60px] w-2 rounded-full bg-[#F04438]' />
                  <p className='text-sm md:text-base font-medium text-[#370704] p-3 rounded-xl bg-[#FDDBDB]'>{before}</p>
                </div>
              </div>

              <div className='space-y-2'>
                <div className='flex md:hidden gap-3'>
                  <p className='text-base font-bold text-[#161617]'>After</p>
                  <AiFillCheckCircle className='text-xl text-[#17B26A]' />
                </div>
                <div className='flex items-center gap-3'>
                  <div className='min-h-[60px] w-2 rounded-full bg-[#17B26A]' />
                  <p className='text-sm md:text-base font-medium text-[#052415] p-3 rounded-xl bg-[#D1F9E7]'>{after}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Benefits