import React from "react";
import logo from "../assets/home/logo.svg";
import googleplay from "../assets/googleplay.svg";
import applestore from "../assets/applestore.svg";
import { SocialIcon } from "react-social-icons";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { cn } from "../lib/utils";
import LinkedInLogo from "../assets/linkedin.svg";
import XLogo from "../assets/x.svg";
import MediumLogo from "../assets/medium.svg";

type Props = {};

const Footer = (props: Props) => {
  const location = useLocation();

  const isAboutPage = location.pathname === "/about-us";
  return (
    <footer
      className={cn(
        "px-4 py-8 lg:px-20 bg-[#F9F9F9]",
        isAboutPage && "bg-gray-50"
      )}
    >
      <section className="flex flex-col items-center justify-center gap-4 lg:flex-row lg:items-center lg:justify-between">
        <aside>
          <figure>
            <img
              src={logo}
              alt=""
              className="h-[22px] w-[139px] object-contain"
            />
          </figure>
        </aside>
        <aside className="flex gap-4">
          <img
            src={LinkedInLogo}
            alt="linkedIn"
            className="h-[26px] w-[26px] cursor-pointer"
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/nurovantai/",
                "_blank"
              );
            }}
          />
          <img
            src={XLogo}
            alt="x"
            className="h-[26px] w-[26px] cursor-pointer"
            onClick={() => {
              window.open(
                "https://x.com/nurovant43999?t=JdVNnKGvs0_J2TXIzYF84Q&s=09",
                "_blank"
              );
            }}
          />
          <img
            src={MediumLogo}
            alt="medium"
            className="h-[26px] w-[26px] cursor-pointer"
            onClick={() => {
              window.open("https://medium.com/@nurovantai/", "_blank");
            }}
          />
        </aside>
      </section>

      <div className="mt-4 flex flex-col gap-4 text-[#98A2B3] items-center justify-center lg:flex-row lg:justify-between ">
        <aside>
          <p>
            &copy;{new Date().getFullYear()} Nurovant AI. All rights reserved.
          </p>
        </aside>

        <div>
          <Link to="/contact" className="hover:text-primary text-[#98A2B3]">
            Contact Sales
          </Link>
          <Link to="/terms" className="ml-4 hover:text-primary text-[#98A2B3]">
            Terms of Service
          </Link>
          <Link
            to="/privacy"
            className="ml-4 hover:text-primary text-[#98A2B3]"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
