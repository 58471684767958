import React from "react";
import Hero from "../../components/students/Hero";
import Information from "../../components/students/Information";

type Props = {};

const Students = (props: Props) => {
  return (
    <main>
      <section>
        <Hero />
      </section>
      <section className="mt-8 lg:mt-0" id="info">
        <Information />
      </section>
    </main>
  );
};

export default Students;
