import React, { useState } from "react";

type Props = {};

const Newsletter = (props: Props) => {
  const [email, setEmail] = useState("");

  const sendMail = (e: any) => {
    e.preventDefault();
    const encodedBody = encodeURIComponent("I want to join your newsletter.");
    window.open(
      `mailto:support@nurovant.com?subject=Join%20newsletter&body=${encodedBody}`,
    );
  };
  return (
    <main className="mt-28 max-w-[1216px] bg-primary py-16 text-center lg:mx-auto lg:rounded-2xl lg:px-[80px]">
      <h1 className="font-GontserratBold text-[24px] font-bold text-white lg:text-[48px]">
        Join our careers newsletter
      </h1>
      <p className="mt-4 text-[#E9D7FE]">
        Be the first to know when new jobs are posted!
      </p>
      <form
        onSubmit={sendMail}
        className="mx-auto mt-6 flex w-full max-w-[327px] justify-between gap-2 rounded-md bg-white p-3 !px-3 font-montserrat"
      >
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border-none focus:border-0 focus:border-none focus:outline-none"
          placeholder="Enter your email"
        />
        <button className="border-none text-primary">Subscribe</button>
      </form>
    </main>
  );
};

export default Newsletter;
