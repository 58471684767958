import React, { useEffect, useState } from "react";
import { teacherInfo, teacherPerks } from "../../lib/teachers-info";
import image1 from "../../assets/teachers/image1.png";
import image2 from "../../assets/teachers/image2.png";
import image3 from "../../assets/teachers/image3.png";
import image1Mobile from "../../assets/students/image1-mobile.png";

import { cn } from "../../lib/utils";
import DownloadApps from "../DownloadApps";

type Props = {};

const Information = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <main className="bg-[#E5E8FD] px-4 py-10 lg:px-20 lg:py-[72px]">
      <section>
        <h1 className="text-center font-GontserratBold text-[28px]">
          Transformative Teaching Tools
        </h1>
        <aside className="mx-auto my-12 grid w-fit max-w-[1216px] grid-cols-1 items-center justify-center gap-7 xl:grid-cols-3">
          <div className="max-w-[384px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Automate Assessments & Grading:
            </h2>
            <p className="pt-2">
              Say goodbye to the endless hours of creating tests and grading.
              Our AI effortlessly crafts assessments and provides accurate
              grading, allowing you to dedicate more time to what truly
              matters—teaching.
            </p>
          </div>
          <div className="max-w-[384px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Minimize Office Hour Questions:
            </h2>
            <p className="pt-2">
              Reduce the need for extensive office hours with our AI-driven
              chatbot, designed to answer students' questions about class
              materials, freeing up your schedule for deeper educational
              engagements.
            </p>
          </div>
          <div className="max-w-[384px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Dynamic Student Engagement:
            </h2>
            <p className="pt-2">
              Captivate your classroom with interactive lessons that not only
              intrigue but inspire. Our platform fosters a vibrant learning
              atmosphere, encouraging active participation and a love for
              learning.
            </p>
          </div>
        </aside>
      </section>
      <section className="my-3">
        <h1 className="text-center font-GontserratBold text-[28px]">
          Insightful Analytics for Targeted Teaching
        </h1>
        <aside className="mx-auto my-12 grid w-fit max-w-[1216px] items-center justify-items-center gap-7 xl:grid-cols-2">
          <div className="max-w-[592px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Deep Dive into Student Performance:
            </h2>
            <p className="pt-2">
              With advanced analytics at your fingertips, gain insights into
              individual and class-wide performance. Easily identify areas where
              students excel or struggle, allowing for tailored support and
              interventions.
            </p>
          </div>
          <div className="max-w-[592px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Progress Tracking & Feedback:
            </h2>
            <p className="pt-2">
              Monitor real-time progress and provide meaningful feedback. Our
              intuitive dashboard offers a comprehensive view of student
              achievements and areas for improvement, ensuring every learner
              stays on track.
            </p>
          </div>
        </aside>
      </section>
      <section className="my-3">
        <h1 className="text-center font-GontserratBold text-[28px]">
          Content That Resonates
        </h1>
        <aside className="mx-auto my-12 grid w-fit max-w-[1216px] items-center justify-items-center gap-7 xl:grid-cols-2">
          <div className="max-w-[592px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Engaging, Interactive Lessons:
            </h2>
            <p className="pt-2">
              Access an extensive library filled with interactive content
              designed to promote critical thinking, mindfulness, and
              social-emotional learning (SEL). Each lesson is a step towards
              creating a more engaging and thoughtful classroom.
            </p>
          </div>
          <div className="max-w-[592px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Customizable Assessments from Lectures:
            </h2>
            <p className="pt-2">
              Transform your lectures into interactive quizzes tailored to
              reinforce learning and prepare students for success. Our AI not
              only generates quizzes based on your material but also adapts to
              student readiness, making learning both effective and enjoyable.
            </p>
          </div>
        </aside>
      </section>
      <section>
        <h1 className="text-center font-GontserratBold text-[28px]">
          Empowerment Through Technology
        </h1>
        <aside className="mx-auto my-12 grid w-fit max-w-[1216px] grid-cols-1 items-center justify-center gap-7 xl:grid-cols-3">
          <div className="max-w-[384px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Confidence in Teaching:
            </h2>
            <p className="pt-2">
              Approach every class with confidence, knowing you have the tools
              to make a difference. Our platform ensures you're equipped to meet
              challenges head-on, turning potential into excellence.
            </p>
          </div>
          <div className="max-w-[384px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Achieve Academic Excellence:
            </h2>
            <p className="pt-2">
              Elevate your teaching and propel students toward their academic
              goals. Nurovant's powerful platform supports a diverse range of
              educational needs, making every learning journey successful.
            </p>
          </div>
          <div className="max-w-[384px]">
            <h2 className="text-[18px] font-semibold text-[#4970FC]">
              Efficiency in Education:
            </h2>
            <p className="pt-2">
              Optimize both teaching and learning with focused resources that
              streamline educational experiences. Our technology not only
              enhances learning outcomes but also makes education more
              accessible and enjoyable for everyone involved.
            </p>
          </div>
        </aside>
      </section>

      <DownloadApps
        title="Ready to ace those exams?"
        text=" Sign up for a free trial today and discover the endless possibilities for enhancing student well-being and academic success!"
      />
    </main>
  );
};

export default Information;
