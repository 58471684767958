import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function Auth() {
  const [types, setTypes] = useState([
    { icon: "👨‍🎓", label: "A Student", path: "https://app.nurovant.com", active: false },
    { icon: "👩‍🏫", label: "An Educator", path: "https://school.nurovant.com", active: false },
  ])
  return (
    <div className='w-full min-h-[80vh] sm:w-[70vw] md:w-[50vw] lg:w-[40vw] mx-auto p-5 md:p-10 lg:px-20 flex flex-col justify-center items-center gap-5'>
      <div className='w-full'>
        <p className='text-2xl font-bold text-[#161617]'>Let’s get you started</p>
        <p className='text-sm font-semibold text-[#57585A]'>What type of user are you? This will determine the features you’ll have access to.</p>
      </div>

      <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-5'>
        {types.map(({icon, label, active}) => {
          const arrangeType = types.map(d => ({...d, active: (d?.label === label)}))
          const handleSelect = () => setTypes(arrangeType)

          const activeClass = "!bg-[#E1E7FF] !border-[#093EFB] !text-[#093EFB]"
          const hoverClass = "hover:!bg-[#E1E7FF] hover:!border-[#093EFB] hover:!text-[#093EFB]"
          const divClass = "w-full rounded-xl bg-[#F5F5F5E5] text-[#161617] text-center py-7 space-y-2 cursor-pointer border border-transparent"
          return (
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40] relative">
            <div onClick={handleSelect} className={divClass.concat(" ", hoverClass, " ", active ? activeClass : "")}>
              <p className='text-2xl'>{icon}</p>
              <p className='text-sm font-bold'>{label}</p>
            </div>
          </div>
        )})}
      </div>

      <button
        disabled={!types.some(({ active }) => active)}
        onClick={() => (window.location.href = types.find(({ active }) => active)?.path || "")}
        className='w-full h-[50px] rounded-full text-base font-bold text-white bg-[#4970FC] disabled:bg-[#E1E7FF] hover:bg-[#093EFB]'
      >Continue</button>

      <div className='flex justify-center items-center gap-2'>
        <p className='text-base font-medium text-[#161617]'>Already have an account?</p>
        <Link to={types.find(({ active }) => active)?.path || ""} className='text-sm font-bold text-[#4970FC]'>Login</Link>
      </div>

      <div className='text-sm font-normal text-[#858689] text-center space-y-3 my-20'>
        <p>By registering, you agree to our <u>Terms of Service</u> and <u>Privacy Policy</u></p>
        <p>© NurovantAI {new Date().getFullYear()}. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Auth