import React from "react";
import Pricing from "../../components/home/Pricing";
import LogoSlider from "../../components/home/LogoSlider";
import TopHero from "../../components/home/TopHero";
import Benefits from "../../components/home/Benefits";
import Integration from "../../components/home/Integration";
import Cta from "../../components/home/Cta";
import Testimonials from "../../components/home/Testimonials";
import Faq from "../../components/home/Faq";

type Props = {};

const Home = (props: Props) => {
  return (
    <main className="h-full w-full font-montserrat">
      <TopHero />
      <LogoSlider />
      <Benefits />
      <Integration />

      <section>
        <Pricing />
      </section>
      <Testimonials />
      <section>
        <Faq />
      </section>
      <Cta />
    </main>
  );
};

export default Home;
