export const studyInfo = [
  {
    id: 0,
    title: "Flashcards",
    text: "Master key concepts and reinforce your understanding with interactive flashcards. Create custom flashcard sets for any subject or topic, and study at your own pace. With features like flip, shuffle, and self-assessment mode, mastering new material has never been easier.",
  },
  {
    id: 1,
    title: "Recaps",
    text: "Stay on top of your coursework and review important information with recaps. Our platform generates concise summaries of lessons, lectures, and readings, allowing you to quickly review key points and concepts.",
  },
  {
    id: 2,
    title: "Quiz",
    text: "Test your knowledge and track your progress with interactive quizzes. Challenge yourself with a variety of question types and receive instant feedback on your performance.",
  },
];

export const studyPerks = [
  {
    title: "Enhanced Retention",
    text: "Engage in active learning and improve information retention with interactive flashcards and recaps that reinforce key concepts and facts.",
  },
  {
    title: "Time Efficiency",
    text: "Maximize your study time and minimize procrastination with streamlined study guides and recaps that provide focused review materials exactly when you need them",
  },
  {
    title: "Personalized Learning",
    text: "Customize your study experience with flashcards and study guides tailored to your unique learning style, preferences, and academic goals.",
  },
];
