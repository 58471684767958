import React from "react";
import Hero from "../../components/institutions/Hero";
import Information from "../../components/institutions/Information";
import { Helmet } from "react-helmet-async";

type Props = {};

const Institutions = (props: Props) => {
  return (
    <main>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16573273738"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16573273738');
          `}
        </script>
      </Helmet>
      <section>
        <Hero />
      </section>
      <section className="mt-8 lg:mt-0">
        <Information />
      </section>
    </main>
  );
};

export default Institutions;
