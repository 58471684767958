import { Check, CheckCircle } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Button } from "../ui/button";
import { HeaderTag } from "../HeaderTag";

type Props = {};

type PricingTier = {
  name: string;
  price: number;
  description: string;
  features: string[];
  ctaText: string;
};

const pricingTiers: PricingTier[] = [
  // {
  //   name: "FREE",
  //   price: 0,
  //   description: "Everything you need to know about the product and more",
  //   features: [
  //     "Everything you need to know about the product and more",
  //     "Everything you need to know about the product and more",
  //   ],
  //   ctaText: "Start for free",
  // },
  {
    name: "PREMIUM",
    price: 9.99,
    description: "Everything you need to know about the product and more",
    features: [
      "Access to basic features",
      "20GB individual data each user",
      "Basic reporting and analytics",
      "Basic chat and email support",
      "Up to 10 individual users",
      "Everything you need to know about the product and more",
    ],
    ctaText: "Get started",
  },
  // {
  //   name: "PRO",
  //   price: 24.99,
  //   description: "Everything you need to know about the product and more",
  //   features: [
  //     "Everything you need to know about the product and more",
  //     "Everything you need to know about the product and more",
  //     "Everything you need to know about the product and more",
  //     "Everything you need to know about the product and more",
  //   ],
  //   ctaText: "Get started",
  // },
];

const Pricing = (props: Props) => {
  const [isMonthly, setIsMonthly] = useState(true);

  return (
    <main className="px-4 py-[45px] lg:py-[96px] flex flex-col items-center justify-center gap-[30px] lg:gap-[45px] bg-pricing-bg bg-cover bg-center">
      <div className="flex flex-col gap-[20px] items-center justify-center">
        <div className="flex items-center justify-center">
          <HeaderTag text="Pricing 💰" />
        </div>
        <div className="flex flex-col gap-[15px] items-center justify-center max-w-[591px]">
          <h1 className="text-[30px] leading-[38px] text-center lg:text-[36px] lg:leading-[44px] font-semibold text-[#101828]">
            We like keeping things simple. One plan one price
          </h1>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4">
        <div className="relative flex items-center justify-center flex-col gap-7 lg:flex-row lg:gap-0">
          {pricingTiers.map((tier, index) => (
            // <Card
            //   key={tier.name}
            //   className={`relative transition-all duration-300 p-[30px] h-fit ${
            //     tier.name === "PREMIUM"
            //       ? "z-20 transform lg:scale-100 border-primary border-2 rounded-[30px] drop-shadow-xl drop-shadow-primary"
            //       : "z-10"
            //   } ${
            //     tier.name === "FREE" &&
            //     "rounded-l-[30px] rounded-r-[30px] lg:rounded-r-none  md:mr-[-10px] "
            //   } ${
            //     tier.name === "PRO" &&
            //     "rounded-r-[30px] rounded-l-[30px] lg:rounded-l-none  md:ml-[-10px] "
            //   }`}
            // >
            //   <CardHeader className="p-0 mb-[23px]">
            //     <CardTitle>{tier.name}</CardTitle>
            //     <p className="text-sm text-muted-foreground">
            //       {tier.description}
            //     </p>
            //   </CardHeader>
            //   <CardContent className="p-0 flex flex-col gap-[23px]">
            //     <div className="flex flex-col gap-[20px] pb-4 border-b">
            //       <Button className="w-full">{tier.ctaText}</Button>

            //       <div className="">
            //         <span className="text-4xl font-bold">
            //           ${isMonthly ? tier.price : tier.price * 12}
            //         </span>
            //         <span className="text-muted-foreground">
            //           /{isMonthly ? "month" : "year"}
            //         </span>
            //       </div>
            //     </div>

            //     <div className="">
            //       <h3 className="font-semibold mb-2">Get started with:</h3>
            //       <ul className="space-y-2">
            //         {tier.features.map((feature, index) => (
            //           <li key={index} className="flex items-start">
            //             <CheckCircle className="h-6 w-6 mr-[10px] text-green-500 " />

            //             <span className="text-sm text-[#6D6E71]">
            //               {feature}
            //             </span>
            //           </li>
            //         ))}
            //       </ul>
            //     </div>
            //   </CardContent>
            // </Card>
            <div className="flex flex-col max-w-[744px] gap-[45px] mx-auto">
              <div className="flex flex-row items-center justify-center gap-[7px]">
                <h2 className="text-[54px] leading-[60px] lg:text-[72px] lg:leading-[90px] lg:font-bold -lg:tracking-[2%] font-semibold text-[#101828]">
                  $10
                </h2>
                <div className="w-[119px] flex flex-col">
                  <span className="text-[#6D6E71] text-sm">/monthly</span>
                  <span className="text-[#6D6E71] text-sm ">
                    (billed quarterly)
                  </span>
                </div>
              </div>
              <ul className="flex gap-[15px] w-full flex-wrap justify-center">
                {tier.features.map((feature, index) => (
                  <li key={index} className="flex items-start lg:w-[40%]">
                    <CheckCircle className="h-6 w-6 mr-[10px] text-green-500 flex-shrink-0" />

                    <span className="text-sm text-[#6D6E71]">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-center gap-4">
        <Link
          to="/auth"
          // href={"https://school.nurovant.com/"}
          className="flex text-center text-white bg-[#4970FC] hover:bg-[#4970FC] hover:text-white p-3 px-5 rounded-full text-base font-bold items-center justify-center"
        >
          Get Started 🔥
        </Link>

        <a
          href={"/contact"}
          className="text-center bg-[#F5F5F5] text-[#161617] p-3 px-5 rounded-full text-base font-bold flex items-center justify-center"
        >
          Contact Sales 📞
        </a>
      </div>
    </main>
  );
};

export default Pricing;
