import React, { useState } from 'react'
import * as emailjs from "emailjs-com"
import { useLocation } from 'react-router-dom'
import { AiFillCloseCircle } from 'react-icons/ai'
import { TbFidgetSpinner } from 'react-icons/tb'

function ContactForm() {
  const { pathname, hash } = useLocation()
  const [alert, setAlert] = useState<any>()
  const [loading, setLoading] = useState(false)
  const hashText = hash.replaceAll("%20", " ")?.replaceAll("#", "")

  const handleAltClose = () => setAlert({})

  const alerts = {
    error: "border-[#F04438] bg-[#FDDBDB] text-[#370704]",
    success: "border-[#17B26A] bg-[#D1F9E7] text-[#052415]",
  }

  const altClass = alerts?.[alert?.status as keyof typeof alerts]

  const handleSubmit = (d: any) => {
    d.preventDefault();
    const form = new FormData(d?.target);
    const data = Object.fromEntries(form);

    setLoading(true);

    const templateParams = {
      ...data,
      from_email: data?.email,
      to_name: "support@nurovant.com",
      from_name: String(data?.first_name)?.concat(" ", String(data?.last_name)),
    };

    emailjs.send(
      "service_d309lx4",
      "template_ibd02ih",
      templateParams,
      "QeC6QRX299HP8gK4e"
    )
    .then((res: any) => {
        setAlert({
          status: "success",
          message: "Success!",
          description: `${res?.text}, your request was sent successfully.`
        })
        setLoading(false);
      },
      (err: any) => {
        setAlert({
          status: "error",
          message: "Error!",
          description: `An error occurred, ${err?.text}`
        })
        setLoading(false);
      }
    );
  }
  return (
    <div className='w-full p-5 sm:p-10 md:p-20 lg:px-40 xl:px-60 flex flex-col justify-center items-center text-center gap-10'>
      <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
        <a href={pathname.concat(hash)} className="block bg-white cursor-text p-2 px-5 rounded-full text-sm font-semibold capitalize">Contact Sales {hashText} 📞</a>
      </div>
      <p className='text-5xl font-bold text-[#161617]'>Contact our sales team</p>
      <p className='text-lg font-semibold text-[#57585A]'>
        Reach out to us through the contact form or the details provided 
        <br className='hidden md:block' /> 
        below to share your feedback, inquiries, or suggestions. Please be 
        <br className='hidden md:block' /> 
        specific and detailed in your message.
      </p>

      <form onSubmit={handleSubmit} className='w-full grid grid-cols-1 md:grid-cols-2 gap-5 gap-y-7 text-start' method='post'>
        <div className='space-y-1 hidden'>
          <label htmlFor='subject' className='text-base font-medium text-[#161617]'>Subject</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <input value={"Contact Sales ".concat(hashText || "")} name='subject' id='subject' type='text' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' readOnly />
          </div>
        </div>

        <div className='space-y-1'>
          <label htmlFor='first_name' className='text-base font-medium text-[#161617]'>First name *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <input placeholder='First name' name='first_name' id='first_name' type='text' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required />
          </div>
        </div>

        <div className='space-y-1'>
          <label htmlFor='last_name' className='text-base font-medium text-[#161617]'>Last name *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <input placeholder='Last name' name='last_name' id='last_name' type='text' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required />
          </div>
        </div>

        <div className='space-y-1 md:col-span-2'>
          <label htmlFor='email' className='text-base font-medium text-[#161617]'>Business Email *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <input placeholder='business.mail@nurovant.com' name='email' id='email' type='email' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required />
          </div>
        </div>

        <div className='space-y-1'>
          <label htmlFor='company_name' className='text-base font-medium text-[#161617]'>Company Name *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <input placeholder='Nurovant AI' name='company_name' id='company_name' type='text' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required />
          </div>
        </div>

        <div className='space-y-1'>
          <label htmlFor='job_title' className='text-base font-medium text-[#161617]'>Job Title *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <input placeholder='Lead Designer' name='job_title' id='job_title' type='text' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required />
          </div>
        </div>

        <div className='space-y-1'>
          <label htmlFor='industry' className='text-base font-medium text-[#161617]'>Industry *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <select name='industry' id='industry' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required>
              {[
                "Select an industry",
                "Aerospace",
                "Automotive",
                "Chemicals",
                "Construction",
                "Consumer Goods",
                "Defense",
                "Electronics",
                "Energy",
                "Food and Beverage",
                "Healthcare",
                "Heavy Machinery",
                "Hospitality",
                "Logistics",
                "Manufacturing",
                "Metals",
                "Mining",
                "Oil and Gas",
                "Pharmaceuticals",
                "Renewable Energy",
                "Retail",
                "Telecommunications",
                "Textiles",
                "Transportation",
                "Utilities",
                "Waste Management",
                "Water Industries",
                "Other"
              ].map(d => (<option value={d}>{d}</option>))}
            </select>
          </div>
        </div>

        <div className='space-y-1'>
          <label htmlFor='company_size' className='text-base font-medium text-[#161617]'>Company Size *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <select name='company_size' id='company_size' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required>
              {[
                "Select company size",
                "1-50 employees",
                "51-150 employees",
                "151-500 employees",
                "501-1,000 employees",
                "1,001-5,000 employees",
                "5,000+ employees",
              ].map(d => (<option value={d}>{d}</option>))}
            </select>
          </div>
        </div>

        <div className='space-y-1 md:col-span-2'>
          <label htmlFor='use_case' className='text-base font-medium text-[#161617]'>Which of our use case areas are you most interested in? *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <select name='use_case' id='use_case' className='w-full h-[60px] px-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required>
              {[
                "Select use case interest",
                "Sales Automation",
                "Operations Automation",
                "Finance Automation",
                "Support Automation",
                "Data Entry Automation",
                "Other"
              ].map(d => (<option value={d}>{d}</option>))}
            </select>
          </div>
        </div>

        <div className='space-y-1 md:col-span-2'>
          <label htmlFor='message' className='text-base font-medium text-[#161617]'>Message *</label>
          <div className="rounded-xl p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
            <textarea name='message' id='message' rows={5} className='w-full min-h-[60px] p-5 bg-[#F5F5F5E5] rounded-xl outline-none border-primary' required />
          </div>
        </div>

        <div hidden={!altClass} className='w-full md:col-span-2'>
          <div className={'w-full flex justify-between items-center py-2 px-5 rounded-lg border '.concat(altClass)}>
            <div className='flex items-center gap-2'>
              <p className='text-base font-medium'>{alert?.message || "Status"}</p>:
              <p className='text-sm font-normal'>{alert?.description || "status of action is unknown!"}</p>
            </div>
            <AiFillCloseCircle onClick={handleAltClose} className='text-xl cursor-pointer' />
          </div>
        </div>

        <div className='md:col-span-2 flex justify-end items-end'>
          <button disabled={loading} type='submit' className="flex items-center gap-3 py-3 px-20 text-center text-white bg-[#4970FC] hover:bg-[#F5F5F5] hover:text-[#4970FC] rounded-full">
            <TbFidgetSpinner className={'animate-spin text-lg '.concat(String(!loading && "!hidden"))} />
            <p className='text-base font-bold'>Submit</p>
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm