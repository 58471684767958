import React from 'react'

function Integration() {
  return (
    <div className='w-full min-h-screen bg-[#161617] p-5 md:p-10 text-center' id='integration'>
      <div className='w-full min-h-screen bg-integration-bg bg-cover md:bg-contain bg-no-repeat bg-center flex flex-col justify-center items-center gap-5'>
        <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
          <p className="block text-white bg-[#161617] p-1 px-3 rounded-full text-sm font-semibold">Integrations ⚙️</p>
        </div>
        <p className='md:px-20 text-3xl md:text-4xl font-bold text-[#F9F9F9] md:leading-[40px]'>Effortlessly integrate Nurovant with <br className='hidden md:block' /> the tools you’re already using.</p>
        <p className='text-base font-normal text-white'>Our integrations ensure all your workforce workflows connect effortlessly <br className='hidden md:block' /> with us, providing a smooth experience for your team.</p>
      </div>
    </div>
  )
}

export default Integration